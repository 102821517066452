<template>
  <div class="text-center center">
    <v-divider class="mt-8" />
    <div class="grey--text mt-8">
      {{ $t("quiz.lti.footer", {name: currentUser.name }) }}
      <img
        src="@/assets/images/logo_evalmee.png"
        alt="logo evalmee"
        class="mt-2 logo-footer center"
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "IntegrationFooter",
  computed: {
    ...mapGetters(["currentUser"]),
  },
}
</script>

<style scoped>
  .logo-footer {
    width: 100px;
    display: block;
  }
</style>
